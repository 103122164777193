import { useCallback, useState } from "react";
import { FaBackspace } from "react-icons/fa";
import { Button, CircularProgress, TextField } from "@mui/material";
import { BLUE, EMAIL_TO_USER } from "../definitions";
import { callEndpoint } from "../util";
import {
  CreateTransactionEndpoint,
  Kasse1Email,
  TransactionMode,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { DotBar } from "./DotBar";
import { useNotifcation } from "../hooks/useNotification";

const CellStyle = {
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

interface KeypadProps {
  emails: { own: Kasse1Email; other: Kasse1Email };
  addOptimisticTransaction(transaction: {
    mode: TransactionMode;
    amount: number;
    description: string;
  }): void;
  refresh(): void;
}

export function Keypad(props: KeypadProps) {
  const [amount, setAmount] = useState("");
  const [selectedMode, setSelectedMode] = useState<TransactionMode>(
    TransactionMode.Solo
  );
  const [selectedUser, setSelectedUser] = useState<Kasse1Email>(
    props.emails.own
  );
  const [description, setDescription] = useState("");
  const [isCreating, setIsCreating] = useState(false);

  const notify = useNotifcation();

  function pressNumber(number: string) {
    if (number === "0" && amount === "") {
      return;
    }

    if (amount.length >= 4) {
      return;
    }

    setAmount((curr) => curr + number);
  }

  function clearNumber() {
    setAmount((curr) => curr.slice(0, -1));
  }

  const changeMode = useCallback(() => {
    if (
      selectedMode === TransactionMode.Solo &&
      selectedUser === props.emails.own
    ) {
      setSelectedUser(props.emails.other);
    } else if (
      selectedMode === TransactionMode.Solo &&
      selectedUser === props.emails.other
    ) {
      setSelectedUser(props.emails.own);
      setSelectedMode(TransactionMode.Transfer);
    } else if (
      selectedMode === TransactionMode.Transfer &&
      selectedUser === props.emails.own
    ) {
      setSelectedUser(props.emails.other);
    } else {
      setSelectedUser(props.emails.own);
      setSelectedMode(TransactionMode.Solo);
    }
  }, [selectedMode, selectedUser, props.emails.own, props.emails.other]);

  async function createTransaction() {
    if (amount.length === 0) {
      notify("Indtast venligst et beløb", "info");
      return;
    }

    if (
      selectedMode === TransactionMode.Solo &&
      selectedUser === props.emails.other &&
      !window.confirm(`Tilføj udgift for ${EMAIL_TO_USER[props.emails.other]}?`)
    ) {
      return;
    }

    if (
      selectedMode === TransactionMode.Transfer &&
      selectedUser === props.emails.own &&
      !window.confirm(`Send penge til ${EMAIL_TO_USER[props.emails.other]}?`)
    ) {
      return;
    }

    if (
      selectedMode === TransactionMode.Transfer &&
      selectedUser === props.emails.other &&
      !window.confirm(`Modtag penge fra ${EMAIL_TO_USER[props.emails.other]}?`)
    ) {
      return;
    }

    const transaction = {
      mode: selectedMode,
      user: selectedUser,
      amount: -Number(amount),
      description,
    };

    props.addOptimisticTransaction(transaction);

    setAmount("");
    setDescription("");
    setSelectedMode(TransactionMode.Solo);
    setSelectedUser(props.emails.own);

    const [err] = await callEndpoint(CreateTransactionEndpoint, transaction, {
      setWorking: setIsCreating,
    });

    props.refresh();

    if (err) {
      notify(`Der skete en fejl: ${err.error}`);
    }
  }

  // boolean is true if author and user is equal
  const TRANSACTION_TYPE_TO_TEXT: Record<
    TransactionMode,
    Record<"false" | "true", string>
  > = {
    [TransactionMode.Solo]: { true: "", false: "På vegne af" },
    [TransactionMode.Transfer]: { true: "Send til", false: "Modtag fra" },
  };

  return (
    <div
      style={{
        color: "rgba(0,0,0,0.7)",
        position: "fixed",
        bottom: 64,
        width: "calc(100vw - 16px)",
        marginLeft: 8,
      }}
    >
      <div
        style={{
          marginBottom: 120,
          textAlign: "center",
          marginTop: 48,
          fontSize: 50,
          display: "grid",
          alignItems: "center",
          justifyItems: "center",
          gridTemplateColumns: "1fr 1fr 1fr",
        }}
      >
        <div />
        <div style={{ display: "flex", alignItems: "center" }}>
          <div>{amount}</div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className="blinking-cursor">|</span>
          </div>
          <div>kr.</div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginRight: 24,
          }}
        >
          {amount.length > 0 && (
            <FaBackspace style={{ fontSize: 30 }} onClick={clearNumber} />
          )}
        </div>
      </div>
      <div
        style={{
          display: "flex",
          columnGap: 16,
          justifyContent: "center",
          marginBottom: 8,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          style={{ textTransform: "none" }}
          onClick={() => setDescription("Mad")}
        >
          Mad
        </Button>
        <Button
          variant="outlined"
          size="small"
          style={{ textTransform: "none" }}
          onClick={() => setDescription("Snacks")}
        >
          Snacks
        </Button>
        <Button
          variant="outlined"
          size="small"
          style={{ textTransform: "none" }}
          onClick={() => setDescription("Fritid")}
        >
          Fritid
        </Button>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <TextField
          label="Beskrivelse"
          variant="standard"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginBottom: 8, width: "calc(100% - 36px)" }}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr 1fr",
          gridAutoRows: 60,
          fontSize: 24,
          fontWeight: 500,
          alignItems: "center",
          justifyItems: "center",
          background: "white",
          borderRadius: 8,
          border: "1px solid rgba(0,0,0,0.1)",
        }}
      >
        <div style={CellStyle} onClick={() => pressNumber("1")}>
          1
        </div>
        <div style={CellStyle} onClick={() => pressNumber("2")}>
          2
        </div>
        <div style={CellStyle} onClick={() => pressNumber("3")}>
          3
        </div>
        <div style={CellStyle} onClick={() => pressNumber("4")}>
          4
        </div>
        <div style={CellStyle} onClick={() => pressNumber("5")}>
          5
        </div>
        <div style={CellStyle} onClick={() => pressNumber("6")}>
          6
        </div>
        <div style={CellStyle} onClick={() => pressNumber("7")}>
          7
        </div>
        <div style={CellStyle} onClick={() => pressNumber("8")}>
          8
        </div>
        <div style={CellStyle} onClick={() => pressNumber("9")}>
          9
        </div>
        <div style={{ ...CellStyle }} onClick={changeMode}>
          <div style={{ display: "grid", gridTemplateRows: "1fr auto 1fr" }}>
            <div
              style={{
                fontSize: 12,
                fontWeight: "normal",
                textAlign: "center",
              }}
            >
              {
                TRANSACTION_TYPE_TO_TEXT[selectedMode][
                  `${props.emails.own === selectedUser}`
                ]
              }
            </div>
            <div>
              {selectedMode === TransactionMode.Transfer
                ? EMAIL_TO_USER[props.emails.other]
                : EMAIL_TO_USER[selectedUser]}
            </div>
            <DotBar
              selectedMode={selectedMode}
              isSameUser={selectedUser === props.emails.own}
              style={{ marginTop: 2 }}
            />
          </div>
        </div>
        <div style={CellStyle} onClick={() => pressNumber("0")}>
          0
        </div>
        <div style={CellStyle}>
          <div
            style={{
              padding: "4px 20px",
              borderRadius: 50,
              background: BLUE,
              color: "white",
              width: 32,
              textAlign: "center",
            }}
            onClick={createTransaction}
          >
            {isCreating ? (
              <CircularProgress
                color="inherit"
                size={20}
                style={{ marginBottom: -2, color: "white" }}
              />
            ) : (
              "OK"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
