import { useEffect, useRef } from "react";

export function useChangeTopColor(color: string) {
  const isMounted = useRef(true);

  useEffect(() => {
    const previousColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = color;
    return () => {
      isMounted.current = false;
      document.body.style.backgroundColor = previousColor;
    };
  }, [color]);
}
