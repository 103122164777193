import { Button, Dialog, TextField } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { REGEX_POSITIVE_INTEGER_OR_EMPTY } from "../definitions";
import { callEndpoint } from "../util";
import {
  CreateTransactionEndpoint,
  Kasse1Email,
  TransactionMode,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { useNotifcation } from "../hooks/useNotification";

interface EditModalProps {
  balance: number;
  user: Kasse1Email;
  refresh(): void;
  onClose(): void;
}

export function TransferModal(props: EditModalProps) {
  const [value, setValue] = useState("");
  const [isTransferring, setIsTransferring] = useState(false);

  const notify = useNotifcation();

  useEffect(() => setValue(`${Math.abs(props.balance)}`), [props.balance]);

  async function transfer() {
    setIsTransferring(true);

    const [err] = await callEndpoint(CreateTransactionEndpoint, {
      user: props.user,
      mode: TransactionMode.Solo,
      amount: Number(value),
      description: "Overførsel",
    });
    setIsTransferring(false);

    if (err) {
      notify(`Der skete en fejl: ${err.error}`);
      return;
    }

    props.refresh();
    props.onClose();
  }

  const invalid = useMemo(
    () =>
      value === "" || Number(value) + props.balance > 0 || Number(value) < 0,
    [props.balance, value]
  );

  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    setTimeout(() => inputRef.current?.focus(), 1);
  }, []);

  return (
    <Dialog onClose={props.onClose} open fullWidth>
      <div style={{ padding: 24 }}>
        <div style={{ marginBottom: 24, color: "rgba(0, 0, 0, 0.7)" }}>
          Der mangler at blive overført {Math.abs(props.balance)} kr.
        </div>
        <TextField
          type="number"
          value={value}
          onChange={(e) => {
            if (!REGEX_POSITIVE_INTEGER_OR_EMPTY.test(e.target.value)) {
              return;
            }
            setValue(e.target.value);
          }}
          slotProps={{
            htmlInput: {
              inputMode: "numeric",
              pattern: "[0-9]*",
              style: { textAlign: "center", fontSize: 30 },
              ref: inputRef,
            },
          }}
          autoFocus
          variant="standard"
          style={{ width: "100%" }}
        />
        <Button
          variant="contained"
          onClick={transfer}
          disabled={isTransferring || invalid}
          style={{ textTransform: "none", width: "100%", marginTop: 32 }}
        >
          {isTransferring ? "..." : "Overfør"}
        </Button>
      </div>
    </Dialog>
  );
}
