import { useMemo, useState } from "react";
import { EMAIL_TO_USER, TEXT_GREEN } from "../definitions";
import { splitPending, toDate } from "../util";
import { EditModal } from "./EditModal";
import { FaChevronLeft } from "react-icons/fa";
import { Button } from "@mui/material";
import { TransferModal } from "./TransferModal";
import {
  Kasse1Email,
  Transaction,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { UserInfo } from "@ckal-software/ckal-lib";
import { LogViewer } from "./LogViewer";
import { UserPanel } from "./UserPanel";

interface HistoryProps {
  userInfo: UserInfo;
  selectedUser: Kasse1Email;
  transactions: Transaction[];
  onBack(): void;
  refresh(): void;
  onLogout(): void;
}

export function History(props: HistoryProps) {
  const [selectedTransactionId, setSelectedTransactionId] = useState("");
  const [count, setCount] = useState(10);
  const [showTransferModal, setShowTransferModal] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  const selectedTransaction = useMemo(
    () => props.transactions.find((t) => t._id === selectedTransactionId),
    [selectedTransactionId, props.transactions]
  );

  const balance = useMemo(
    () => props.transactions.reduce((sum, t) => sum + t.amount, 0),
    [props.transactions]
  );

  const mappedTransactions: (Transaction & { pending?: true })[] =
    useMemo(() => {
      const { pending, nonPending } = splitPending(props.transactions);

      return [...pending.map((t) => ({ ...t, pending: true })), ...nonPending];
    }, [props.transactions]);

  const name =
    props.userInfo.email === props.selectedUser
      ? "Du"
      : EMAIL_TO_USER[props.selectedUser];

  return (
    <div style={{ overflow: "auto", height: "100vh" }}>
      {showLogs && <LogViewer onClose={() => setShowLogs(false)} />}
      <div style={{ margin: 8 }}>
        {selectedTransaction && (
          <EditModal
            refresh={props.refresh}
            onClose={() => setSelectedTransactionId("")}
            transaction={selectedTransaction}
          />
        )}
        {showTransferModal && (
          <TransferModal
            balance={balance}
            onClose={() => setShowTransferModal(false)}
            refresh={props.refresh}
            user={props.selectedUser}
          />
        )}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr auto 1fr",
            fontSize: 24,
            textAlign: "center",
            marginBottom: 24,
            alignItems: "center",
            marginTop: 24,
          }}
          onClick={props.onBack}
        >
          <FaChevronLeft style={{ fontSize: 24, marginLeft: 18 }} />
          <div>Historik</div>
        </div>
        <div
          style={{
            color: "rgba(0,0,0,0.7)",
            textAlign: "center",
          }}
        >
          {balance === 0
            ? `${name} skylder ingenting 👍`
            : balance > 0
            ? `${name} har ${balance} kr. til gode 👏`
            : `${balance} kr. mangler at blive overført`}
        </div>
        {balance < 0 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: 16,
              marginBottom: 16,
              padding: "0 8px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => setShowTransferModal(true)}
              style={{
                width: "100%",
                textTransform: "unset",
              }}
            >
              Registrer overførsel
            </Button>
          </div>
        )}
        <div
          style={{
            background: "white",
            fontSize: 16,
            marginTop: 20,
            color: "rgba(0,0,0,0.7)",
            border: "1px solid rgba(0,0,0,0.1)",
            borderRadius: 8,
            overflow: "hidden",
          }}
        >
          {mappedTransactions.slice(0, count).map((t, i) => (
            <div
              key={t._id}
              style={{
                borderBottom:
                  i === mappedTransactions.length - 1
                    ? undefined
                    : "1px solid rgba(0,0,0,0.1)",
                padding: 16,
                display: "grid",
                gridTemplateColumns: "108px auto 68px",
                alignItems: "center",
                columnGap: 16,
                position: "relative",
              }}
              onClick={() => setSelectedTransactionId(t._id)}
            >
              {t.pending && (
                <div
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: 2,
                    background: "#0098ff",
                  }}
                />
              )}
              {t.user !== t.addedBy && (
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: 10,
                    marginTop: 4,
                    position: "absolute",
                    left: 16,
                    bottom: 4,
                    color: "rgba(0,0,0,0.55)",
                  }}
                >
                  {`Tilføjet af ${EMAIL_TO_USER[t.addedBy]}`}
                </div>
              )}
              {t.transferId && (
                <div
                  style={{
                    fontStyle: "italic",
                    fontSize: 10,
                    marginTop: 4,
                    position: "absolute",
                    right: 16,
                    bottom: 4,
                    color: "rgba(0,0,0,0.55)",
                  }}
                >
                  Direkte overførsel
                </div>
              )}
              <div style={{ whiteSpace: "nowrap" }}>{toDate(t.timestamp)}</div>
              <div
                style={{
                  margin: "-12px 0",
                  fontSize: 14,
                  fontStyle: "italic",
                  color: "rgba(0,0,0,0.55)",
                }}
              >
                {t.description}
              </div>
              <div
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "end",
                  color: t.amount > 0 ? TEXT_GREEN : undefined,
                }}
              >
                {t.amount} kr.
              </div>
            </div>
          ))}
        </div>
        {count < mappedTransactions.length && (
          <div
            style={{
              color: "#489fd7",
              textAlign: "center",
              marginTop: 18,
            }}
            onClick={() => setCount((c) => c + 20)}
          >
            Vis flere
          </div>
        )}
        <UserPanel
          style={{ margin: "64px 20px 0 20px" }}
          onLogsClick={() => setShowLogs(true)}
          onLogout={props.onLogout}
        />
        <div style={{ height: 100 }} />
      </div>
    </div>
  );
}
