import { BLUE } from "../definitions";

interface DotProps {
  selected: boolean;
}

export function Dot(props: DotProps) {
  return (
    <div
      style={{
        background: props.selected ? BLUE : "#c3c3c3",
        height: 6,
        width: props.selected ? 14 : 6,
        borderRadius: 3,
        transition: "width 0.4s, background-color 0.4s",
      }}
    />
  );
}
