import { BaseVariant, useSnackbar } from "notistack";
import { useCallback } from "react";

export function useNotifcation() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notify = useCallback(
    (message: string, variant?: Exclude<BaseVariant, "error">) => {
      const id = enqueueSnackbar({
        message,
        variant: variant ?? "error",
        SnackbarProps: { onClick: () => closeSnackbar(id) },
        autoHideDuration: 2000,
      });
    },
    [enqueueSnackbar, closeSnackbar]
  );

  return notify;
}
