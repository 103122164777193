import { TransactionMode } from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { Dot } from "./Dot";
import { CSSProperties } from "react";

interface DotBarProps {
  selectedMode: TransactionMode;
  isSameUser: boolean;
  style?: CSSProperties;
}

export function DotBar(props: DotBarProps) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        columnGap: 4,
        ...props.style,
      }}
    >
      <Dot
        selected={
          props.selectedMode === TransactionMode.Solo && props.isSameUser
        }
      />
      <Dot
        selected={
          props.selectedMode === TransactionMode.Solo && !props.isSameUser
        }
      />
      <Dot
        selected={
          props.selectedMode === TransactionMode.Transfer && props.isSameUser
        }
      />
      <Dot
        selected={
          props.selectedMode === TransactionMode.Transfer && !props.isSameUser
        }
      />
    </div>
  );
}
