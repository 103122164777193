import { useEffect, useState } from "react";
import { CredentialsHandler, LF_LOGS } from "./definitions";
import {
  getCallInternalEndpointFunction,
  getWebPushPermissionState,
  now,
} from "@ckal-software/ckal-lib";
import { Transaction } from "@ckal-software/ckal-lib/dist/apps/kasse1";
import localforage from "localforage";

export function toDate(timestamp: string | undefined) {
  if (!timestamp) {
    return "";
  }

  const date = new Date(timestamp);

  const month = [
    "januar",
    "februar",
    "marts",
    "april",
    "maj",
    "juni",
    "juli",
    "august",
    "september",
    "oktober",
    "november",
    "december",
  ][date.getMonth()];

  return `${date.getDate()}. ${month}`;
}

export async function getHeaders(customHeaders?: Record<string, string>) {
  const headers = new Headers();

  headers.append("Content-Type", "application/json");
  headers.append("Authorization", await CredentialsHandler.getAccessToken());

  if (customHeaders) {
    Object.entries(customHeaders).forEach(([k, v]) => headers.set(k, v));
  }

  return headers;
}

export const callEndpoint = getCallInternalEndpointFunction(
  CredentialsHandler.getAccessToken,
  log
);

export function useIsPermissionGranted() {
  const [granted, setGranted] = useState(false);

  useEffect(() => {
    getWebPushPermissionState().then((state) =>
      setGranted(state === "granted")
    );
  }, []);

  return granted;
}

export function splitPending(transactions: Transaction[]): {
  nonPending: Transaction[];
  pending: Transaction[];
} {
  const sortedTransactions = [...transactions].sort((a, b) =>
    a.timestamp.localeCompare(b.timestamp)
  );

  // First determine latest point at which the balance was 0
  let latestTimestampAtBalance0 = "";
  let balance = 0;

  sortedTransactions.forEach((t) => {
    balance += t.amount;

    if (balance === 0) {
      latestTimestampAtBalance0 = t.timestamp;
    }
  });

  return {
    nonPending: transactions.filter(
      (t) => t.timestamp <= latestTimestampAtBalance0
    ),
    pending: transactions.filter(
      (t) => t.timestamp > latestTimestampAtBalance0
    ),
  };
}

export function log(error: Error | string) {
  console.log(error);

  localforage.getItem(LF_LOGS, (_e, v) => {
    const newLogs = [
      {
        timestamp: now(),
        message:
          typeof error === "string" ? error : error.stack || error.message,
      },
    ];

    if (Array.isArray(v)) {
      newLogs.push(...v);
    }

    localforage.setItem(LF_LOGS, newLogs.slice(0, 100));
  });
}
