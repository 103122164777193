import { Status } from "./Status";
import { Keypad } from "./Keypad";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { History } from "./History";
import { callEndpoint } from "../util";
import {
  Kasse1Email,
  Transaction,
  TransactionMode,
  TransactionsEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { LF_TRANSACTIONS, UserInfoKasse1 } from "../definitions";
import {
  ApplicationName,
  EMAIL_CASPER,
  EMAIL_CECILIE,
  freshId,
  now,
  sendPushSubscription,
} from "@ckal-software/ckal-lib";
import localforage from "localforage";
import { useNotifcation } from "../hooks/useNotification";

interface MainProps {
  userInfo: UserInfoKasse1;
  onLogout(): void;
}

export function Main(props: MainProps) {
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isFetchingTransactions, setIsFetchingTransactions] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Kasse1Email>();
  const [hasFetchedOnce, setHasFetchedOnce] = useState(false);

  const notify = useNotifcation();

  useEffect(() => {
    localforage.setItem(LF_TRANSACTIONS, transactions);
  }, [transactions]);

  useEffect(() => {
    localforage.getItem(LF_TRANSACTIONS, (_, transactions) => {
      if (Array.isArray(transactions)) {
        setTransactions(transactions);
      } else {
        console.log(
          "Cached transactions could not be parsed, was:",
          transactions
        );
      }
    });
  }, []);

  const selectedTransactions = useMemo(
    () => transactions.filter((t) => t.user === selectedUser),
    [selectedUser, transactions]
  );

  useEffect(() => {
    if ("Notification" in window && navigator.serviceWorker) {
      Notification.requestPermission().then(async (permission) => {
        if (permission === "granted") {
          sendPushSubscription(callEndpoint, ApplicationName.Kasse1);
        }
      });
    }
  }, []);

  const fetchTransactions = useCallback(async () => {
    const [err, resultTransactions] = await callEndpoint(
      TransactionsEndpoint,
      undefined,
      { setWorking: setIsFetchingTransactions }
    );
    setHasFetchedOnce(true);

    if (err) {
      notify(`Der skete en fejl: ${err.error}`);
      return;
    }

    setTransactions(resultTransactions);
  }, [notify]);

  const refresh = useCallback(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  const toggleRef = useRef(false);

  useEffect(() => {
    refresh();

    function refreshOnVisibilityChange() {
      if (toggleRef.current) {
        refresh();
      }
      toggleRef.current = !toggleRef.current;
    }

    document.addEventListener("visibilitychange", refreshOnVisibilityChange);

    return () => {
      document.removeEventListener(
        "visibilitychange",
        refreshOnVisibilityChange
      );
    };
  }, [refresh]);

  const otherEmail = useMemo(
    () =>
      props.userInfo.email === EMAIL_CASPER ? EMAIL_CECILIE : EMAIL_CASPER,
    [props.userInfo.email]
  );

  function addOptimisticTransaction(transaction: {
    user: Kasse1Email;
    mode: TransactionMode;
    amount: number;
    description: string;
  }) {
    const { amount, description, mode, user } = transaction;

    if (mode === TransactionMode.Solo) {
      setTransactions((curr) =>
        curr.concat({
          _id: freshId(),
          user,
          amount,
          description,
          addedBy: props.userInfo.email,
          timestamp: now(),
        })
      );
    } else {
      // Money goes from one person to the other, we create two, linked transactions
      setTransactions((curr) =>
        curr.concat(
          ...[
            {
              // Money out
              _id: freshId(),
              user,
              amount,
              description,
              addedBy: props.userInfo.email,
              timestamp: now(),
              transferId: "placeholder",
            },
            {
              // Money in
              _id: freshId(),
              user: (user === EMAIL_CASPER
                ? EMAIL_CECILIE
                : EMAIL_CASPER) as Kasse1Email,
              amount: -amount,
              description,
              addedBy: props.userInfo.email,
              timestamp: now(),
              transferId: "placeholder",
            },
          ]
        )
      );
    }
  }

  return (
    <div>
      {selectedTransactions.length > 0 && selectedUser ? (
        <History
          userInfo={props.userInfo}
          selectedUser={selectedUser}
          transactions={selectedTransactions}
          onBack={() => setSelectedUser(undefined)}
          refresh={fetchTransactions}
          onLogout={props.onLogout}
        />
      ) : (
        <div
          style={{
            height: "100vh",
            overflow: "hidden",
          }}
        >
          <Status
            onUserSelect={setSelectedUser}
            isFetching={!hasFetchedOnce && isFetchingTransactions}
            transactions={transactions}
          />
          <Keypad
            emails={{ own: props.userInfo.email, other: otherEmail }}
            refresh={fetchTransactions}
            addOptimisticTransaction={addOptimisticTransaction}
          />
        </div>
      )}
    </div>
  );
}
