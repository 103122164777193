import {
  CKALCredentialsHandler,
  EMAIL_CASPER,
  EMAIL_CECILIE,
  UserInfo,
} from "@ckal-software/ckal-lib";
import {
  Kasse1Email,
  TransactionsEndpoint,
} from "@ckal-software/ckal-lib/dist/apps/kasse1";
import { z } from "zod";

export const LF_TRANSACTIONS = "lf_kasse1_transactions";
export const LF_LOGS = "lf_kasse1_logs";

export const EMAIL_TO_USER: Record<Kasse1Email, "Casper" | "Cecilie"> = {
  [EMAIL_CASPER]: "Casper",
  [EMAIL_CECILIE]: "Cecilie",
};

export const CredentialsHandler = new CKALCredentialsHandler("kasse1");

const compose = (...regexes: RegExp[]) =>
  new RegExp(regexes.map((regex) => regex.source).join("|"));
export const REGEX_EMPTY = new RegExp(/^$/);
export const REGEX_POSITIVE_INTEGER = new RegExp(/^[1-9]\d{0,10}$/);
export const REGEX_POSITIVE_INTEGER_OR_EMPTY = compose(
  REGEX_POSITIVE_INTEGER,
  REGEX_EMPTY
);

export type UserInfoKasse1 = Omit<UserInfo, "email"> & {
  email: typeof EMAIL_CASPER | typeof EMAIL_CECILIE;
};

export type TransactionsResponse = z.infer<
  (typeof TransactionsEndpoint)["response"]
>;

export const TEXT_GREEN = "#51b135";
export const BLUE = "#3089f9";
